import gql from "graphql-tag";

export const GET_ITEM = gql`
  query GetItemById($id: ID!, $sort: String!) {
    item(id: $id) {
      id
      createdAt
      daysOwned
      accepct
      sold
      right {
        productVariant {
          product {
            handle
          }
        }
        id
        createdAt
        isOnSale
        rank
        owned {
          silver
          gold
          platinum
          oikos
        }
        ownedOld {
          silver
          gold
          platinum
        }
        song {
          title
        }
        album {
          title
        }
        artist {
          name
        }
        image {
          url
        }
        tradingLogs {
          price
          createdAt
          priceDifference
        }
        tradingLogOlds {
          discountCount
          shopifyOrderId
          productVariantId
          targetSelection
          price
          createdAt
        }
        relatedRights(order: $sort) {
          id
          rank
          percentage
          shopifyOrderId
          sumPercentageOrder
          sumPriceOrder
          discountCount
          targetSelection
          newBoughtAt
          quantity
          productVariant {
            shopifyVariantTitle
            price
            rank
            inventory
          }
        }
      }
    }
  }
`;

export const GET_OWNED_ALBUMS = gql`
  query OwnedAlbums($tradingLogId: Int) {
    ownedAlbums(tradingLogId: $tradingLogId) {
      id
      title
      songs {
        id
        title
      }
    }
  }
`;

export const GET_SONG_ANALYSIS = gql`
  query SongAnalysis(
    $tradingLogId: Int!
    $period: Int
    $songId: Int
    $albumId: Int
  ) {
    songAnalysis(
      tradingLogId: $tradingLogId
      period: $period
      songId: $songId
      albumId: $albumId
    ) {
      streamPlayCount {
        date
        playCount
      }
      royalty {
        month
        amount
      }
      articles {
        title
        handle
        publishedAt
        tags
      }
      totalPrice
      totalPriceOld
      totalPriceInMigrate
      revenueSharePercent
      streamPlayCountChangePercent
    }
  }
`;

export const GET_SONG_ANALYSIS_FOR_ROYALTY = gql`
  query SongAnalysis(
    $tradingLogId: Int!
    $period: Int
    $songId: Int
    $albumId: Int
  ) {
    songAnalysis(
      tradingLogId: $tradingLogId
      period: $period
      songId: $songId
      albumId: $albumId
    ) {
      streamPlayCount {
        date
        playCount
      }
      royalty {
        month
        amount
      }
      articles {
        title
        handle
        publishedAt
        tags
      }
      totalPrice
      revenueSharePercent
      streamPlayCountChangePercent
    }
  }
`;
