import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import CountUp from 'react-countup';
import { Line } from 'react-chartjs-2';
import { useQuery } from '@shopify/react-graphql';

import { ConvertTerm } from '../../utils';
import { GetHeightForGraph, GetWidthForGraph } from '../../utils/graph';
import { chartjs_options_for_Holdings, chartjs_options_for_Royalty } from '../../utils/chart';
import { GET_HOLDINGS, GET_HOLDINGS_GRAPH, GET_ROYALTY, GET_ROYALTY_CHANGE_RATE, GET_ROYALTY_GRAPH } from '../../query/collection';

export default function RoyaltyAndHoldings() {
    const [
    ShowRoyaltyDialog,
    ShowHoldingsDialog,
  ] = useOutletContext();
  const [royaltyTabSelected, setRoyaltyTabSelected] = useState(false);
  const [holdingsTabSelected, setHoldingsTabSelected] = useState(false);
  const [royaltyPeriod, setRoyaltyPeriod] = useState(0);
  const [holdingsPeriod, setHoldingsPeriod] = useState(0);

   const royaltyInitial = {
    days90: false,
    days180: false,
    days365: false,
    daysAll: false
  }
  const [royaltySelected, setRoyaltySelected] = useState(royaltyInitial)
  const holdingsInitial = {
    days90: false,
    days180: false,
    days365: false,
    daysAll: false
  }
  const [holdingsSelected, setHoldingsSelected] = useState(holdingsInitial)

  const {
    data: royaltyData,
    refetch: royaltyRefetch,
  } = useQuery(GET_ROYALTY, {
    variables: { period: 89 },
  });

  const {
    data: holdingsGraphData,
    refetch: holdingsGraphRefetch,
  } = useQuery(GET_HOLDINGS_GRAPH, {
    variables: { period: 89 },
  });

  const {
    data: royaltyChangeRateData,
    refetch: royaltyChangeRateRefetch,
  } = useQuery(GET_ROYALTY_CHANGE_RATE, {
    variables: { period: 89 },
  });

  const {
    data: royaltyGraphData,
    refetch: royaltyGraphRefetch,
  } = useQuery(GET_ROYALTY_GRAPH, {
    variables: { period: 89 },
  });

  const {
    refetch: holdingsRefetch,
  } = useQuery(GET_HOLDINGS, {
    variables: { period: 89 },
  });

  useEffect(() => {
    ShowTab("royalty");
    ShowRoyalty(90);
    ShowHoldings(90);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ShowRoyalty(period) {
    setRoyaltyPeriod(period);

    if (period === 90) {
      setRoyaltySelected({...royaltyInitial, days90: true})
    } else if (period === 180) {
      setRoyaltySelected({...royaltyInitial, days180: true})
    } else if (period === 365) {
      setRoyaltySelected({...royaltyInitial, days365: true})
    } else if (period === 0) {
      setRoyaltySelected({...royaltyInitial, daysAll: true})
    }

    let tmp_period;
    if (period === 0) {
      tmp_period = period;
    } else {
      tmp_period = period - 1;
    }
    royaltyRefetch({ period: tmp_period });
    royaltyGraphRefetch({ period: tmp_period });
    royaltyChangeRateRefetch({ period: tmp_period });
  }

  function ShowHoldings(period) {
    setHoldingsPeriod(period);

    if (period === 90) {
      setHoldingsSelected({...holdingsInitial, days90: true})
    } else if (period === 180) {
      setHoldingsSelected({...holdingsInitial, days180: true})
    } else if (period === 365) {
      setHoldingsSelected({...holdingsInitial, days365: true})
    } else if (period === 0) {
      setHoldingsSelected({...holdingsInitial, daysAll: true})
    }

    let tmp_period;
    if (period === 0) {
      tmp_period = period;
    } else {
      tmp_period = period - 1;
    }
    holdingsRefetch({ period: tmp_period });
    holdingsGraphRefetch({ period: tmp_period });
  }

  function ShowHoldingsTotalPrice(data) {
    if (data && data?.totalPricePerDay?.length > 0) {
      return data.totalPricePerDay[data?.totalPricePerDay?.length - 1].price;
    } else {
      return 0;
    }
  }


  function ShowTab(tab) {
    if (tab === "royalty") {
      setRoyaltyTabSelected(true);
      setHoldingsTabSelected(false);
      ShowRoyalty(999);
      ShowRoyalty(royaltyPeriod);
    } else if (tab === "holdings") {
      setRoyaltyTabSelected(false);
      setHoldingsTabSelected(true);
      ShowHoldings(999);
      ShowHoldings(holdingsPeriod);
    }
  }

  function CreateHoldingsData(data) {
    const labels = data?.totalPricePerDay?.map((d) => {
      if (window.innerWidth >= 1024) {
        return d.date.replaceAll("-", "/")
      } else {
        let date = d.date.split("-");
        return parseInt(date[1]) + "/" + parseInt(date[2]);
      }
    });
    const dt = data?.totalPricePerDay?.map((d) => d.price);

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }
    function CreateRoyaltyData(data) {
    if (royaltyData?.totalEarnings?.passedFirstReflection === false) {
      return {
        labels: [],
        datasets: [
          {
            label: "",
            tension: 0,
            data: [],
            borderColor: "rgb(89, 50, 255)",
            backgroundColor: "rgba(89, 50, 255, 0.2)",
            fill: true,
          },
        ],
      };
    }

    const labels = data?.rateEarningsChangeAndTotalPerMonth.earnings.map((d) => {
      if (window.innerWidth >= 1024) {
        return d.date.replaceAll("-", "/");
      } else {
        const date = d.date.split("-");
        return parseInt(date[1]) + "/" + parseInt(date[2]);
      }
    });
    const dt = data?.rateEarningsChangeAndTotalPerMonth?.earnings?.map((d) => d.earnings);

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }

  function ShowStrRoyaltyChangeRate() {
    if (
      royaltyChangeRateData?.rateEarningsChangeAndTotalPerMonth?.earnings
        ?.length === 0
    ) {
      return <>※まだデータがありません</>;
    } else if (royaltyData?.totalEarnings?.passedFirstReflection === false) {
      return (
        <>※購入したOIKOSの「初回分配日」を迎えるとデータが更新されます。</>
      );
    } else {
      return (
        <>
          {ShowUpDown(
            royaltyChangeRateData?.rateEarningsChangeAndTotalPerMonth
              ?.changeRate
          )}
          過去{ConvertTerm(royaltyPeriod)}で
          {Math.ceil(
            royaltyChangeRateData?.rateEarningsChangeAndTotalPerMonth
              ?.changeRate * 100
          )}
          %変化しました
        </>
      );
    }
  }

  function ShowStrHoldingsChangeRate() {
    if (holdingsGraphData?.totalPricePerDay.length === 0) {
      return <>※まだデータがありません</>;
    } else {
      return (
        <>
          <font class="up">▲</font>過去{ConvertTerm(holdingsPeriod)}で
          {GetHoldingsChangeRate()}
          %変化しました
        </>
      );
    }
  }

  function ShowUpDown(rate) {
    if (rate < 0) {
      return (
        <>
          <font class="down">▼</font>
        </>
      );
    } else {
      return (
        <>
          <font class="up">▲</font>
        </>
      );
    }
  }

  function GetHoldingsChangeRate() {
    let rate = 0;
    if (
      holdingsGraphData?.totalPricePerDay?.length > 0 &&
      holdingsGraphData?.totalPricePerDay[0]?.price > 0
    )
      rate =
        (holdingsGraphData?.totalPricePerDay[
          holdingsGraphData?.totalPricePerDay?.length - 1
        ].price -
          holdingsGraphData?.totalPricePerDay[0]?.price) /
        holdingsGraphData?.totalPricePerDay[0]?.price;
    return Math.ceil(rate * 100);
  }

  return (
    <div className="royalty-and-holdings">
      <div className="tab">
        <span
          onClick={() => ShowTab("royalty")}
          className={royaltyTabSelected ? "active" : ""}
        >
          ロイヤリティー
        </span>
        <span
          onClick={() => ShowTab("holdings")}
          className={holdingsTabSelected ? "active" : ""}
        >
          保有OIKOS総額
        </span>
      </div>
      <div
        className={
          royaltyTabSelected ? "chart-royalty active" : "chart-royalty"
        }
      >
        <div className="title">
          ロイヤリティー
          <span onClick={() => ShowRoyaltyDialog()} className="tips"></span>
        </div>
        <div className="royalty">
          ¥
          <CountUp
            end={
              royaltyData?.totalEarnings?.passedFirstReflection === false
                ? 0
                : Math.floor(royaltyData?.totalEarnings?.earnings * 100) /
                  100
            }
            decimal="."
            decimals={
              (Math.floor(royaltyData?.totalEarnings?.earnings * 100) /
                100) %
                1 !==
              0
                ? 2
                : 0
            }
          />
        </div>
        <div className="changerate">{ShowStrRoyaltyChangeRate()}</div>

        <div class="collection-royalty-chart-box">
          <Line
            width={GetWidthForGraph()}
            height={GetHeightForGraph()}
            options={chartjs_options_for_Royalty}
            data={CreateRoyaltyData(royaltyGraphData)}
          />
        </div>

        <div className="royalty-period-selection-area">
          <ul>
            <li>
              <span
                onClick={() => ShowRoyalty(90)}
                className={`button-small-container ${royaltySelected.days90 ? "active" : ""}`}
              >
                90日
              </span>
            </li>
            <li>
              <span
                onClick={() => ShowRoyalty(180)}
                className={`button-small-container ${royaltySelected.days180 ? "active" : ""}`}
              >
                180日
              </span>
            </li>
            <li>
              <span
                onClick={() => ShowRoyalty(365)}
                className={`button-small-container ${royaltySelected.days365 ? "active" : ""}`}
              >
                365日
              </span>
            </li>
            <li>
              <span
                onClick={() => ShowRoyalty(0)}
                className={`button-small-container ${royaltySelected.daysAll ? "active" : ""}`}
              >
                全期間
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={
          holdingsTabSelected ? "chart-holdings active" : "chart-holdings"
        }
      >
        <div className="title">
          保有OIKOS総額
          <span onClick={() => ShowHoldingsDialog()} className="tips"></span>
        </div>
        <div className="holdings">
          ¥
          <CountUp
            end={ShowHoldingsTotalPrice(holdingsGraphData)}
            decimal="."
            decimals={
              ShowHoldingsTotalPrice(holdingsGraphData) % 1 !== 0
                ? 2
                : 0
            }
          />
        </div>
        <div className="changerate">{ShowStrHoldingsChangeRate()}</div>

        <div class="collection-holdings-chart-box">
          <Line
            width={GetWidthForGraph()}
            height={GetHeightForGraph()}
            options={chartjs_options_for_Holdings}
            data={CreateHoldingsData(holdingsGraphData)}
          />
        </div>

        <div className="holdings-period-selection-area">
          <ul>
            <li>
              <span
                onClick={() => ShowHoldings(90)}
                className={`button-small-container ${holdingsSelected.days90 ? "active" : ""}`}
              >
                90日
              </span>
            </li>
            <li>
              <span
                onClick={() => ShowHoldings(180)}
                className={`button-small-container ${holdingsSelected.days180 ? "active" : ""}`}
              >
                180日
              </span>
            </li>
            <li>
              <span
                onClick={() => ShowHoldings(365)}
                className={`button-small-container ${holdingsSelected.days365 ? "active" : ""}`}
              >
                365日
              </span>
            </li>
            <li>
              <span
                onClick={() => ShowHoldings(0)}
                className={`button-small-container ${holdingsSelected.daysAll ? "active" : ""}`}
              >
                全期間
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
