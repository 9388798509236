import React, { createContext, useReducer, useEffect } from "react";
import GlobalReducer from "./GlobalReducer";
import { getCookie, setCookie } from "./utils/cookie";

const initialState = {
  accessToken: null,
  songDetailPage: "",
};

const GlobalState = ({ children }) => {
  const [state, dispatch] = useReducer(
    GlobalReducer,
    initialState,
    (state) => ({ accessToken: getCookie("access_token") } || initialState)
  );
  useEffect(() => {
    setCookie("access_token", state.accessToken);
  });
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default GlobalState;
