import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import CountUp from "react-countup";
import { useOutletContext } from 'react-router-dom';
import { ConvertTerm } from '../../utils';
import { GetHeightForGraph, GetWidthForGraph } from '../../utils/graph';
import { chartJs_options_for_Streaming } from '../../utils/chart';
import { GET_PLAY_COUNT, GET_PLAY_COUNT_CHANGE_RATE, GET_PLAY_COUNT_GRAPH } from '../../query/collection';
import { useQuery } from '@shopify/react-graphql';

export default function PlayCountArea() {
    const playCountSelectedInitial = {
    days7: false,
    days28: false,
    days90: false,
    days180: false,
    days365: false,
    daysAll: false
  }
  const [playCountSelected, setPlayCountSelected] = useState(playCountSelectedInitial)
  const [playCountPeriod, setPlayCountPeriod] = useState(0);
  const [ ShowStreamDialog] = useOutletContext();
  const {
    data: playCountGraphData,
    refetch: playCountGraphRefetch,
  } = useQuery(GET_PLAY_COUNT_GRAPH, {
    variables: { period: 6 },
  });
  const {
    data: playCountData,
    refetch: playCountRefetch,
  } = useQuery(GET_PLAY_COUNT, {
    variables: { period: 6 },
  });
    const {
    data: playCountChangeRateData,
    refetch: playCountChangeRateRefetch,
  } = useQuery(GET_PLAY_COUNT_CHANGE_RATE, {
    variables: { period: 6 },
  });

  useEffect(()=>{
    ShowPlayCount(7);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function ShowPlayCount(period) {
    setPlayCountPeriod(period);

    if (period === 7) {
      setPlayCountSelected({...playCountSelectedInitial, days7: true})
    } else if (period === 28) {
      setPlayCountSelected({...playCountSelectedInitial, days28: true})
    } else if (period === 90) {
      setPlayCountSelected({...playCountSelectedInitial, days90: true})
    } else if (period === 180) {
      setPlayCountSelected({...playCountSelectedInitial, days180: true})
    } else if (period === 365) {
      setPlayCountSelected({...playCountSelectedInitial, days365: true})
    } else if (period === 0) {
      setPlayCountSelected({...playCountSelectedInitial, daysAll: true})
    }

    let tmp_period;
    if (period === 0) {
      tmp_period = period;
    } else {
      tmp_period = period - 1;
    }
    playCountRefetch({ period: tmp_period });
    playCountGraphRefetch({ period: tmp_period });
    playCountChangeRateRefetch({ period: tmp_period });
  }

  function CreateStreamingData(data) {
    const {totalPlayCountPerDay = []} = data
    const labels =totalPlayCountPerDay.map((d) => {
      if (window.innerWidth >= 1024) {
        return d.date.replaceAll("-", "/")
      } else {
        let date = d.date.split("-");
        return parseInt(date[1]) + "/" + parseInt(date[2]);
      }
    });
    const dt = totalPlayCountPerDay.map((d) => d.playCount);

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }

  function ShowStrPlayCountChangeRate() {
    if (
      playCountChangeRateData &&
      playCountChangeRateData?.ratePlayCountChange?.changeRate !== null
    ) {
      return (
        <>
          {ShowUpDown(
            playCountChangeRateData?.ratePlayCountChange?.changeRate
          )}
          過去{ConvertTerm(playCountPeriod)}で
          {Math.ceil(
            playCountChangeRateData?.ratePlayCountChange?.changeRate * 100
          )}
          %変化しました
        </>
      );
    } else {
      return <>※まだデータがありません</>;
    }
  }

  function ShowUpDown(rate) {
    if (rate < 0) {
      return (
        <>
          <font class="down">▼</font>
        </>
      );
    } else {
      return (
        <>
          <font class="up">▲</font>
        </>
      );
    }
  }

  return (
    <div className="playcount-area">
      <div className="title">
        ストリーミング再生数
        <span onClick={() => ShowStreamDialog()} className="tips"></span>
      </div>
      <div className="playcount">
        { playCountData && <CountUp
          end={playCountData?.totalPlayCount?.playCount ?? 0}
          decimal=","
        /> }
      </div>
      <div className="changerate">{ShowStrPlayCountChangeRate()}</div>

      <div class="collection-streaming-chart-box">
        <Line
          width={GetWidthForGraph()}
          height={GetHeightForGraph()}
          options={chartJs_options_for_Streaming}
          data={CreateStreamingData(playCountGraphData || {})}
        />
      </div>

      <div className="playcount-period-selection-area">
        <ul>
          <li>
            <span
              onClick={() => ShowPlayCount(7)}
              className={`button-small-container ${playCountSelected.days7 ? "active" : ""}`}
            >
              7日
            </span>
          </li>
          <li>
            <span
              onClick={() => ShowPlayCount(28)}
              className={`button-small-container ${playCountSelected.days28 ? "active" : ""}`}
            >
              28日
            </span>
          </li>
          <li>
            <span
              onClick={() => ShowPlayCount(90)}
              className={`button-small-container ${playCountSelected.days90 ? "active" : ""}`}
            >
              90日
            </span>
          </li>
          <li>
            <span
              onClick={() => ShowPlayCount(180)}
              className={`button-small-container ${playCountSelected.days180 ? "active" : ""}`}
            >
              180日
            </span>
          </li>
          <li>
            <span
              onClick={() => ShowPlayCount(365)}
              className={`button-small-container ${playCountSelected.days365 ? "active" : ""}`}
            >
              365日
            </span>
          </li>
          <li>
            <span
              onClick={() => ShowPlayCount(0)}
              className={`button-small-container ${playCountSelected.daysAll ? "active" : ""}`}
            >
              全期間
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}
