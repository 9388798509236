import React from "react";

const AlbumSongSelection = ({
  albumSongSelectionFlag,
  currentAlbumId,
  currrentSongId,
  ownedAlbumsData,
  SelectAllAlbumSong,
  SelectAlbum,
  SelectSong,
  HideAlbumSongSelection,
}) => {
  return (
    <div
      className={`album-song-selection-area ${
        albumSongSelectionFlag ? "active" : ""
      }`}
    >
      <div className="inner">
        <ul>
          <li>
            <span
              onClick={SelectAllAlbumSong}
              className={
                currentAlbumId === "" && currrentSongId === "" ? "current" : ""
              }
            >
              全収録曲
            </span>
          </li>
          {ownedAlbumsData.ownedAlbums.length > 0 &&
            ownedAlbumsData.ownedAlbums.map((album) => (
              <li key={album.id}>
                <span
                  onClick={() => SelectAlbum(album.id, album.title)}
                  className={
                    currentAlbumId === album.id && currrentSongId === ""
                      ? "current"
                      : ""
                  }
                >
                  {album.title}
                </span>
                {album.songs.length > 0 && (
                  <ul>
                    {album.songs.map((song) => (
                      <li key={song.id}>
                        <span
                          onClick={() =>
                            SelectSong(album.id, song.id, song.title)
                          }
                          className={
                            currentAlbumId === album.id &&
                            currrentSongId === song.id
                              ? "current"
                              : ""
                          }
                        >
                          {song.title}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </div>
      <button
        type="album-song-selection-area-button"
        onClick={HideAlbumSongSelection}
      ></button>
    </div>
  );
};

export default AlbumSongSelection;
