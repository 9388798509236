import { useContext, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Context } from "../GlobalState";

const Authentication = () => {
  const { accessToken } = useParams();
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch({ type: "SET_ACCESS_TOKEN", payload: accessToken });
  });
  return <Navigate replace to="/" />;
};
export default Authentication;
