import { useQuery } from "@shopify/react-graphql";
import {
  GET_ITEM,
  GET_OWNED_ALBUMS,
  GET_SONG_ANALYSIS,
  GET_SONG_ANALYSIS_FOR_ROYALTY,
} from "../../../query/collectionDetails";
import {useState} from "react";

export const useCollectionDetails = (id, sortBy) => {
  const OWNEDITEMS_SHOWCOUNT = 10;
  const OWNERLIMITEDCONTENT_SHOWCOUNT = 3;

  const [ownedItemsShowingCount, setOwnedItemsShowingCount] = useState(10);
  const [ownerLimitedContentShowingCount, setOwnerLimitedContentShowingCount] =
    useState(3);

  const [selectedRoyaltyPeriod, setSelectedRoyaltyPeriod] = useState(90);
  const [playcountPeriod, setPlaycountPeriod] = useState(90);
  const [selectedPeriod, setSelectedPeriod] = useState({
    7: false,
    28: false,
    90: true,
    180: false,
    365: false,
    0: false,
  });
  const [albumSongSelectionFlag, setAlbumSongSelectionFlag] = useState(false);
  const [strAlbumSong, setStrAlbumSong] = useState("全収録曲");
  const [strBigAlbumSong, setStrBigAlbumSong] = useState("");

  const [currentAlbumId, setCurrentAlbumId] = useState("");
  const [currentSongId, setCurrentSongId] = useState("");
  const {
    loading: itemLoading,
    error: itemError,
    data: itemData,
    refetch: refetchItem,
  } = useQuery(GET_ITEM, {
    variables: { id, sort: sortBy},
  });
  const { loading: ownedAlbumsLoading, data: ownedAlbumsData } = useQuery(
    GET_OWNED_ALBUMS,
    {
      variables: { tradingLogId: parseInt(id) },
    }
  );

  const {
    loading: songAnalysisForRoyaltyLoading,
    data: songAnalysisForRoyaltyData,
    refetch: refetchSongAnalysisForRoyalty,
  } = useQuery(GET_SONG_ANALYSIS_FOR_ROYALTY, {
    variables: {
      tradingLogId: parseInt(id),
      period: 89,
      songId: ConvertSongId(currentSongId),
      albumId: ConvertAlbumId(currentAlbumId),
    },
  });

  const {
    loading: songAnalysisLoading,
    data: songAnalysisData,
    refetch: refetchSongAnalysis,
  } = useQuery(GET_SONG_ANALYSIS, {
    variables: {
      tradingLogId: parseInt(id),
      period: 89,
      songId: ConvertSongId(currentSongId),
      albumId: ConvertAlbumId(currentAlbumId),
    },
  });

  function ShowAlbumSongSelection() {
    document.body.classList.add("lock");
    document.body.classList.add("dark");
    document.getElementById("html-tag").classList.add("lock");
    document.getElementById("collection-detail").classList.add("dark");
    setAlbumSongSelectionFlag(true);
  }

  function HideAlbumSongSelection() {
    document.body.classList.remove("lock");
    document.body.classList.remove("dark");
    document.getElementById("html-tag").classList.remove("lock");
    document.getElementById("collection-detail").classList.remove("dark");
    setAlbumSongSelectionFlag(false);
  }

  function SelectAllAlbumSong() {
    setCurrentAlbumId("");
    setCurrentSongId("");
    setStrAlbumSong("全収録曲");
    setStrBigAlbumSong(itemData?.item?.right?.album?.title);
    HideAlbumSongSelection();
    refetchSongAnalysis({
      tradingLogId: parseInt(id),
      period: playcountPeriod,
      songId: ConvertSongId(""),
      albumId: ConvertAlbumId(""),
    });
    refetchSongAnalysisForRoyalty({
      tradingLogId: parseInt(id),
      period: selectedRoyaltyPeriod,
      songId: ConvertSongId(""),
      albumId: ConvertAlbumId(""),
    });
  }

  function SelectAlbum(albumId, title) {
    setCurrentAlbumId(albumId);
    setCurrentSongId("");
    setStrAlbumSong(title);
    setStrBigAlbumSong(title);
    HideAlbumSongSelection();
    refetchSongAnalysis({
      tradingLogId: parseInt(id),
      period: playcountPeriod,
      songId: ConvertSongId(""),
      albumId: ConvertAlbumId(albumId),
    });
    refetchSongAnalysisForRoyalty({
      tradingLogId: parseInt(id),
      period: selectedRoyaltyPeriod,
      songId: ConvertSongId(""),
      albumId: ConvertAlbumId(albumId),
    });
  }

  function SelectSong(albumId, songId, title) {
    setCurrentAlbumId(albumId);
    setCurrentSongId(songId);
    setStrAlbumSong(title);
    setStrBigAlbumSong(title);
    HideAlbumSongSelection();
    refetchSongAnalysis({
      tradingLogId: parseInt(id),
      period: playcountPeriod,
      songId: ConvertSongId(songId),
      albumId: ConvertAlbumId(albumId),
    });
    refetchSongAnalysisForRoyalty({
      tradingLogId: parseInt(id),
      period: selectedRoyaltyPeriod,
      songId: ConvertSongId(songId),
      albumId: ConvertAlbumId(albumId),
    });
  }

  function ConvertSongId(songId) {
    if (songId === "") {
      return null;
    } else {
      return parseInt(songId);
    }
  }

  function ConvertAlbumId(albumId) {
    if (albumId === "") {
      return null;
    } else {
      return parseInt(albumId);
    }
  }
  function ShowBigAlbumSong() {
    return itemData?.item?.right?.album?.title || itemData?.item?.right?.song?.title;
  }
  const ShowRoyalty = (period) => {
    setSelectedRoyaltyPeriod(period);

    const tmp_period = period === 0 ? period : period - 1;

    refetchSongAnalysisForRoyalty({
      tradingLogId: parseInt(id),
      period: tmp_period,
      songId: ConvertSongId(currentSongId),
      albumId: ConvertAlbumId(currentAlbumId),
    });
  };
  const ShowPlayCount = (period) => {
    setPlaycountPeriod(period);
    setSelectedPeriod({
      7: period === 7,
      28: period === 28,
      90: period === 90,
      180: period === 180,
      365: period === 365,
      0: period === 0,
    });

    const tmp_period = period === 0 ? period : period - 1;

    refetchSongAnalysis({
      tradingLogId: parseInt(id),
      period: tmp_period,
      songId: ConvertSongId(currentSongId),
      albumId: ConvertAlbumId(currentAlbumId),
    });
  };
  function ShowMoreOwnerLimitedContent() {
    if (
      songAnalysisData?.songAnalysis?.articles?.length >
      ownerLimitedContentShowingCount
    ) {
      setOwnerLimitedContentShowingCount(
        ownerLimitedContentShowingCount + OWNERLIMITEDCONTENT_SHOWCOUNT
      );
    }
  }
  function ShowMoreOwnedItems() {
    if (itemData?.item?.right?.relatedRights?.length > ownedItemsShowingCount) {
      setOwnedItemsShowingCount(ownedItemsShowingCount + OWNEDITEMS_SHOWCOUNT);
    }
  }

  return {
    ShowMoreOwnedItems,
    ShowMoreOwnerLimitedContent,
    HideAlbumSongSelection,
    SelectAllAlbumSong,
    ShowPlayCount,
    SelectSong,
    SelectAlbum,
    ShowRoyalty,
    ShowAlbumSongSelection,
    ShowBigAlbumSong,
    refetchItem,
    refetchSongAnalysis,
    selectedRoyaltyPeriod,
    setSelectedRoyaltyPeriod,
    selectedPeriod,
    songAnalysisForRoyaltyLoading,
    songAnalysisForRoyaltyData,
    currentSongId,
    setCurrentSongId,
    currentAlbumId,
    setCurrentAlbumId,
    itemLoading,
    itemError,
    itemData,
    ownedAlbumsLoading,
    ownedAlbumsData,
    songAnalysisLoading,
    songAnalysisData,
    albumSongSelectionFlag,
    strAlbumSong,
    strBigAlbumSong,
    ownerLimitedContentShowingCount,
    ownedItemsShowingCount,
  };
};
