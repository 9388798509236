export function ShowUpDown(rate) {
  if (rate < 0) {
    return (
      <>
        <font className="down">▼</font>
      </>
    );
  } else {
    return (
      <>
        <font className="up">▲</font>
      </>
    );
  }
}

export function ConvertTerm(period) {
  if (period === 0) {
    return "全期間";
  } else {
    return period + "日";
  }
}
