import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Slider from "react-slick";
import Button from "../components/Button";
import AlbumSongSelection from "../components/CollectionDetails/AlbumSongSelection";
import OwnedItems from "../components/CollectionDetails/OwnedItems";
import RoyaltyChart from "../components/CollectionDetails/RoyaltyChart";
import StreamingChart from "../components/CollectionDetails/StreamingChart";
import { useCollectionDetails } from "../components/CollectionDetails/hook/useCollectionDetails";
import { FormatPrice } from "../utils";

let CollectionDetails_isAllLoaded = false;

const CollectionDetails = () => {
  let navigate = useNavigate();

  const { id } = useParams();

  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const [ShowCollectionDetailStreamDialog, ShowSongRoyaltyDialog] =
    useOutletContext();

  const {
    songAnalysisForRoyaltyLoading,
    playcountPeriod,
    ownerLimitedContentShowingCount,
    ownedAlbumsData,
    currentAlbumId,
    currentSongId,
    selectedRoyaltyPeriod,
    selectedPeriod,
    ownedAlbumsLoading,
    itemLoading,
    itemError,
    itemData,
    ownedItemsShowingCount,
    songAnalysisLoading,
    songAnalysisForRoyaltyData,
    songAnalysisData,
    albumSongSelectionFlag,
    strAlbumSong,
    ShowBigAlbumSong,
    ShowAlbumSongSelection,
    ShowRoyalty,
    SelectAlbum,
    SelectSong,
    ShowPlayCount,
    HideAlbumSongSelection,
    SelectAllAlbumSong,
    ShowMoreOwnerLimitedContent,
    ShowMoreOwnedItems,
  } = useCollectionDetails(id, "rank");
  const sliderSettings = {
    className: "slide",
    slidesToShow: 1,
    speed: 1000,
    fade: false,
    infinite: false,
    draggable: true,
    swipe: true,
    arrows: false,
    dots: true,
  };
  const modalSliderSettings = {
    className: "slide",
    slidesToShow: 1,
    speed: 1000,
    fade: true,
    infinite: false,
    draggable: false,
    arrows: false,
    dots: true,
  };
  const sliderRef = useRef(null);
  const [modalShown, setModalShown] = useState(false);
  const [viewIsWide, setViewIsWide] = useState(false);
  const [motionCount, setMotionCount] = useState(1);

  useEffect(() => {
    if (itemData?.item && !itemData?.item?.accepct) {
      navigate("/");
    }
  }, [itemData?.item?.accepct]);

  const align = () => {
    var w = window.innerWidth;
    var h = window.innerHeight;

    if (w > h) {
      if (!viewIsWide) setViewIsWide(true);
    } else {
      if (viewIsWide) setViewIsWide(false);
    }
  };
  const showModal = (index) => {
    sliderRef.current.slickGoTo(index);
    setModalShown(true);
    align();
    window.addEventListener("resize", align);
  };
  const hideModal = () => {
    setModalShown(false);
  };

  function GoToPayout() {
    CollectionDetails_isAllLoaded = false;
    navigate("/payout");
  }

  function GetWidthForGraph() {
    if (window.innerWidth >= 1024) {
      return 886;
    } else {
      return 330;
    }
  }

  function GetHeightForGraph() {
    if (window.innerWidth >= 1024) {
      return 300;
    } else {
      return 280;
    }
  }

  useEffect(() => {
    const motionTimer = setInterval(function () {
      if (modalShown) {
        let nextCount = motionCount !== 75 ? motionCount + 1 : 1;
        setMotionCount(nextCount);
      }
    }, 66);

    return () => clearInterval(motionTimer);
  }, [modalShown, motionCount]);

  if (itemError) {
    return (
      <main className="collection content">
        <div className="inner not-found">
          <p>コレクションページを開くにはログインが必要です。</p>
          <div className="function">
            <a href={process.env.REACT_APP_OIKOS_URL + "account/login"}>
              LOGINへ
            </a>
          </div>
        </div>
      </main>
    );
  }

  const hasOwnedOldValue =
    itemData?.item?.right?.ownedOld?.silver !== null ||
    itemData?.item?.right?.ownedOld?.gold !== null ||
    itemData?.item?.right?.ownedOld?.platinum !== null;

  if (
    CollectionDetails_isAllLoaded === false &&
    (itemLoading ||
      ownedAlbumsLoading ||
      songAnalysisLoading ||
      songAnalysisForRoyaltyLoading)
  ) {
    return (
      <main className="collection content">
        <div className="inner acquisition">Loading . . .</div>
      </main>
    );
  }
  const shopifyOrderOldIds = itemData?.item?.right?.tradingLogOlds.map(
    (item) => item.shopifyOrderId
  );

  const discountOrderOld = itemData?.item?.right.relatedRights
    .filter((item) => shopifyOrderOldIds.includes(item.shopifyOrderId))
    .reduce((total, currentItem) => total + currentItem?.discountCount, 0);
  const discountOrder = itemData?.item?.right.relatedRights.reduce(
    (total, currentItem) => total + currentItem?.discountCount,
    0
  );

  CollectionDetails_isAllLoaded = true;
  const {  totalPriceOld ,totalPriceInMigrate} = songAnalysisData?.songAnalysis || {};

  const isTotalPriceLesser = totalPriceInMigrate < totalPriceOld;

  const getPriceChange = isTotalPriceLesser
    ? totalPriceOld - totalPriceInMigrate
    : totalPriceInMigrate - totalPriceOld;

  return (
    <>
      <main id="collection-detail" className="detail">
        <div className="viewer">
          <Slider {...sliderSettings}>
            {/* Comment code OIKOS-145*/}
            {/* <div className="item">
              <div className="oikos-mini">
                <ul className="justify-center">
                  <li className="relative hover:cursor-pointer">
                    <div className="bar">
                      <img src="img/bar_platinum2.png" alt=""></img>
                    </div>
                    <div className="group count flex justify-center">
                      <div>{itemData?.item?.right?.owned?.oikos || "0"}</div>
                      {hasOwnedOldValue && (
                        <div className="cursor-pointer ml-1 relative">
                          <Info/>
                          <div className="hidden group-hover:block absolute text-sm p-2 rounded border text-left min-w-[110px] bottom-[110%] left-[110%] bg-white z-[100]">
                            変換前：
                            <div className="flex flex-col justify-start ">
                              <div>・Silver {itemData?.item?.right?.ownedOld?.silver ?? 0}</div>
                              <div>・Gold {itemData?.item?.right?.ownedOld?.gold ?? 0}</div>
                              <div>・Platinum {itemData?.item?.right?.ownedOld?.platinum ?? 0}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="item">
              <div className="jacket">
                <img src={itemData?.item?.right?.image?.url} alt=""></img>
              </div>
            </div>
          </Slider>
          <div className="bg">
            <img src={itemData?.item?.right?.image?.url} alt="" />
          </div>
        </div>

        <div
          id="modal"
          className={`${modalShown ? "modal-view" : ""} ${
            viewIsWide ? "wide" : "tall"
          }`}
        >
          <Slider ref={sliderRef} {...modalSliderSettings}>
            {itemData?.item?.right?.relatedRights?.map((right) => (
              <div className="item" style={{ height: "100%" }} key={right.id}>
                <div className="view" data-loaded="1">
                  <div className="bar" data-type={right.rank}>
                    <span className="preview">
                      <img
                        src={
                          ["silver", "gold", "platinum"].includes(right.rank)
                            ? `img/bar_${right.rank}.png?2`
                            : ""
                        }
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="motion">
                    {Array.from({ length: 75 }, (_, i) => i + 1).map((i) => (
                      <img
                        key={i}
                        src={`motion/platinum/${i}.png`}
                        className={
                          motionCount === i ? "active loaded" : "loaded"
                        }
                        alt={`Motion platinum ${i}`}
                      />
                    ))}
                  </div>
                </div>
                <div className="text">
                  <div className="data">
                    <span>
                      OIKOS
                      {" " +
                        right.productVariant.shopifyVariantTitle
                          .charAt(0)
                          .toUpperCase() +
                        right.productVariant.shopifyVariantTitle.slice(1)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="info">
            <div className="title">{itemData?.item?.right?.song?.title}</div>
            <div className="artist">{itemData?.item?.right?.artist?.name}</div>
          </div>
          <div className="bg">
            <img src={itemData?.item?.right?.image?.url} alt="" />
          </div>
          <button id="modal-close" onClick={hideModal} onTouchEnd={hideModal}>
            <img src="img/icon_close.svg" alt="Close" />
          </button>
        </div>
        <div
          id="modal-overlay"
          onClick={hideModal}
          onTouchEnd={hideModal}
          className={modalShown ? "modal-view" : ""}
        ></div>

        <div className="inner">
          <div className="mx-auto flex gap-[10px] items-center px-[20px] md:px-[40px] pb-[40px]">
            <Link to="/">Collection</Link>
            <span className="">
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                class="h-3"
                viewBox="0 0 14 14"
              >
                <path
                  d="M3.871.604c.44-.439 1.152-.439 1.591 0l5.515 5.515s-.049-.049.003.004l.082.08c.439.44.44 1.153 0 1.592l-5.6 5.6a1.125 1.125 0 0 1-1.59-1.59L8.675 7 3.87 2.195a1.125 1.125 0 0 1 0-1.59z"
                  fill="#000"
                ></path>
              </svg>
            </span>
            <span>{ShowBigAlbumSong()}</span>
          </div>
          <div className="album-title">
            <div className="title">{ShowBigAlbumSong()}</div>
            <div className="artist">{itemData?.item?.right?.artist?.name}</div>
          </div>

          {/* 保有OIKOS情報 */}
          {songAnalysisData && (
            <>
              <div className="oikos-amount !mt-0 !mb-[110px]">
                <h2>保有OIKOS情報</h2>
                <div className="inner">
                  <dl>
                    <dt>収益分配率</dt>
                    <dd>
                      {songAnalysisData.songAnalysis.revenueSharePercent}％
                    </dd>
                  </dl>
                  <div className="border-l border-[#707070] text-center">
                    <div className="text-[18px] font-bold">保有金額</div>
                    <div className="mt-5">
                      <span className="text-[22px] md:text-[38px] font-medium font-poppins relative">
                        ¥{FormatPrice(songAnalysisData.songAnalysis.totalPrice)}
                      </span>
                      {hasOwnedOldValue &&
                        songAnalysisData.songAnalysis.totalPriceOld !==
                          songAnalysisData.songAnalysis.totalPriceInMigrate && (
                          <span
                            className={`text-white absolute ml-4 py-[4px] px-[7px] rounded text-xl ${
                              isTotalPriceLesser ? "bg-red-500" : "bg-green-500"
                            }`}
                          >
                            {isTotalPriceLesser ? "-" : "+"}¥
                            {FormatPrice(getPriceChange)}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <nav>
                  <span onClick={() => GoToPayout()}>内訳・払出</span>
                </nav>
              </div>
            </>
          )}
          {/* 保有OIKOS情報 */}

          <div className="album-song-pulldown">
            <div onClick={ShowAlbumSongSelection}>
              <span>{strAlbumSong}</span>
            </div>
          </div>
          {songAnalysisData && (
            <StreamingChart
              data={songAnalysisData}
              ShowCollectionDetailStreamDialog={
                ShowCollectionDetailStreamDialog
              }
              ShowPlayCount={ShowPlayCount}
              selectedPeriod={selectedPeriod}
              playcountPeriod={playcountPeriod}
              width={GetWidthForGraph()}
              height={GetHeightForGraph()}
            />
          )}
          {songAnalysisForRoyaltyData && (
            <RoyaltyChart
              data={songAnalysisForRoyaltyData}
              ShowSongRoyaltyDialog={ShowSongRoyaltyDialog}
              onShowRoyalty={ShowRoyalty}
              selectedRoyaltyPeriod={selectedRoyaltyPeriod}
              width={GetWidthForGraph()}
              height={GetHeightForGraph()}
            />
          )}
          {songAnalysisData && (
            <>
              <div className="owner-limited-content">
                <h2>オーナー限定コンテンツ</h2>
                <div className="inner">
                  <div className="slide">
                    {songAnalysisData.songAnalysis.articles.length > 0 &&
                      songAnalysisData.songAnalysis.articles.map((log, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div
                              className={
                                i + 1 > ownerLimitedContentShowingCount
                                  ? "hide"
                                  : "slide-item"
                              }
                            >
                              <a
                                href={
                                  process.env.REACT_APP_OIKOS_URL +
                                  "blogs/club_oikos/" +
                                  log.handle
                                }
                              >
                                <dl>
                                  <dt>{log.publishedAt}</dt>
                                  <dd>{log.tags}</dd>
                                </dl>
                                <div className="title">{log.title}</div>
                              </a>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>

                  <nav>
                    <span
                      className={
                        songAnalysisData.songAnalysis.articles.length >
                        ownerLimitedContentShowingCount
                          ? "more"
                          : "more disabled"
                      }
                      onClick={() => ShowMoreOwnerLimitedContent()}
                    >
                      SHOW MORE
                    </span>
                  </nav>
                </div>
              </div>
            </>
          )}
          {itemData?.item?.right?.relatedRights && (
            <OwnedItems
              ownedItems={itemData.item.right.relatedRights}
              tradingLogOlds={itemData.item.right.tradingLogOlds}
              onShowMoreOwnedItems={ShowMoreOwnedItems}
              ownedItemsShowingCount={ownedItemsShowingCount}
              showModal={showModal}
            />
          )}
        </div>
      </main>
      {albumSongSelectionFlag && ownedAlbumsData && (
        <AlbumSongSelection
          albumSongSelectionFlag={albumSongSelectionFlag}
          currentAlbumId={currentAlbumId}
          currentSongId={currentSongId}
          ownedAlbumsData={ownedAlbumsData}
          SelectAllAlbumSong={SelectAllAlbumSong}
          SelectAlbum={SelectAlbum}
          SelectSong={SelectSong}
          HideAlbumSongSelection={HideAlbumSongSelection}
        />
      )}
      <Button
        variant="contained"
        onClick={() => navigate("/")}
        className="mt-20 mx-auto box-border"
      >
        コレクションへ戻る
      </Button>

      <div id="selector-overlay"></div>
    </>
  );
};
export default CollectionDetails;
