function ConvertTerm(period) {
  if (period === 0) {
    return "全期間";
  } else {
    return period + "日";
  }
}

const FormatPrice = (price) => {
  const numPrice = Number(price);

  return numPrice.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export { ConvertTerm, FormatPrice };
