import gql from "graphql-tag";

const GET_ITEMS = gql`
  query GetItems {
    ownedItems {
      id
      totalPrice
      totalPriceOld
      totalPriceInMigrate
      price
      createdAt
      daysOwned
      right {
        id
        createdAt
        isOnSale
        totalPercentage
        owned {
          silver
          gold
          platinum
          oikos
        }
        ownedOld {
          silver
          gold
          platinum
        }
        song {
          title
        }
        album {
          title
        }
        artist {
          name
        }
        thumbnail {
          url
        }
        productVariant {
          shopifyVariantTitle
        }
      }
    }
    soldItems {
      id
      createdAt
      daysOwned
      right {
        id
        createdAt
        isOnSale
        totalPercentage
        owned {
          silver
          gold
          platinum
          custom
        }
        song {
          title
        }
        album {
          title
        }
        artist {
          name
        }
        thumbnail {
          url
        }
      }
    }
    currentUser {
      owned {
        silver
        gold
        platinum
        oikos
      }
    }
  }
`;

const GET_OWNER_LIMITED_CONTENT = gql`
  query graphql($next_page: String) {
    oikosClubArticles(nextPage: $next_page) {
      articles {
        handle
        publishedAt
        tags
        title
      }
      nextPageInfo
    }
  }
`;

const GET_PLAY_COUNT = gql`
  query graphql($period: Int!) {
    totalPlayCount(period: $period) {
      playCount
    }
  }
`;

const GET_PLAY_COUNT_CHANGE_RATE = gql`
  query graphql($period: Int!) {
    ratePlayCountChange(period: $period) {
      changeRate
    }
  }
`;

const GET_ROYALTY = gql`
  query graphql($period: Int!) {
    totalEarnings(period: $period) {
      earnings
      passedFirstReflection
    }
  }
`;

const GET_ROYALTY_CHANGE_RATE = gql`
  query graphql($period: Int!) {
    rateEarningsChangeAndTotalPerMonth(period: $period) {
      earnings {
        date
        earnings
      }
      changeRate
    }
  }
`;

const GET_ROYALTY_GRAPH = gql`
  query graphql($period: Int!) {
    rateEarningsChangeAndTotalPerMonth(period: $period) {
      earnings {
        date
        earnings
      }
      changeRate
    }
  }
`;

const GET_HOLDINGS = gql`
  query graphql($period: Int!) {
    totalPrice(period: $period) {
      price
    }
  }
`;

const GET_HOLDINGS_GRAPH = gql`
  query graphql($period: Int!) {
    totalPricePerDay(period: $period) {
      date
      price
    }
  }
`;

const GET_CUSTODY = gql`
  query graphql {
    totalAndPayableAmount {
      totalAmount
      payableAmount
    }
  }
`;

const GET_PLAY_COUNT_GRAPH = gql`
  query graphql($period: Int!) {
    totalPlayCountPerDay(period: $period) {
      date
      playCount
    }
  }
`;

export {
  GET_ITEMS,
  GET_OWNER_LIMITED_CONTENT,
  GET_PLAY_COUNT,
  GET_ROYALTY,
  GET_ROYALTY_CHANGE_RATE,
  GET_ROYALTY_GRAPH,
  GET_HOLDINGS,
  GET_HOLDINGS_GRAPH,
  GET_CUSTODY,
  GET_PLAY_COUNT_CHANGE_RATE,
  GET_PLAY_COUNT_GRAPH,
};
