function GetWidthForGraph() {
  if (window.innerWidth >= 1024) {
    return 886;
  } else {
    return 330;
  }
}

function GetHeightForGraph() {
  if (window.innerWidth >= 1024) {
    return 300;
  } else {
    return 280;
  }
}

export {
  GetWidthForGraph,
  GetHeightForGraph
}