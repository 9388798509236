function getTooltipForHoldings() {
  let tooltip = document.querySelector(".collection-holdings-chart-tip");
  if (!tooltip) {
    tooltip = document.createElement("div");
    tooltip.className = "collection-holdings-chart-tip";

    const label = document.createElement("div");
    const line = document.createElement("span");
    line.className = "collection-holdings-chart-tip-line";
    tooltip.appendChild(label);
    tooltip.appendChild(line);
    document
      .querySelector(".collection-holdings-chart-box")
      .appendChild(tooltip);
  }
  return tooltip;
}

function externalTooltipHandlerForHoldings(context) {
  const chart = context.chart;
  let tooltip = context.tooltip;

  const el = getTooltipForHoldings();

  if (tooltip.opacity === 0) {
    el.style.opacity = 0;
    return;
  }

  const date = tooltip.title[0];
  const ary = date.split("/").map((val) => Number(val));

  const title = ary.join("<i>/</i>");
  const value = tooltip.body[0].lines[0];

  document.querySelector(".collection-holdings-chart-tip div").innerHTML =
    "<span>" + title + "</span><br><span>¥" + value + "</span>";

  const { offsetLeft: positionX } = chart.canvas;
  el.style.opacity = 1;
  el.style.left = positionX + tooltip.caretX + "px";
  document.querySelector(".collection-holdings-chart-tip-line").style.height =
    tooltip.caretY + 20 + "px";
}

function getTooltipForRoyalty() {
  let tooltip = document.querySelector(".collection-royalty-chart-tip");
  if (!tooltip) {
    tooltip = document.createElement("div");
    tooltip.className = "collection-royalty-chart-tip";

    const label = document.createElement("div");
    const line = document.createElement("span");
    line.className = "collection-royalty-chart-tip-line";
    tooltip.appendChild(label);
    tooltip.appendChild(line);
    document
      .querySelector(".collection-royalty-chart-box")
      .appendChild(tooltip);
  }
  return tooltip;
}

function externalTooltipHandlerForRoyalty(context) {
  const chart = context.chart;
  const tooltip = context.tooltip;

  const el = getTooltipForRoyalty();

  if (tooltip.opacity === 0) {
    el.style.opacity = 0;
    return;
  }

  const date = tooltip.title[0];
  const ary = date.split("/").map((val) => Number(val));

  const title = ary.join("<i>/</i>");
  const value = tooltip.body[0].lines[0];

  document.querySelector(".collection-royalty-chart-tip div").innerHTML =
    "<span>" + title + "</span><br><span>¥" + value + "</span>";

  const { offsetLeft: positionX} = chart.canvas;
  el.style.opacity = 1;
  el.style.left = positionX + tooltip.caretX + "px";
  document.querySelector(".collection-royalty-chart-tip-line").style.height =
    tooltip.caretY + 20 + "px";
}

export const chartjs_options_for_Royalty = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 1,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      labels: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: function (handler) {
          externalTooltipHandlerForRoyalty(handler);
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          color: "#000000",
          align: "center",
          minRotation: 0,
          maxRotation: 0,
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value === 0) {
              return "rgba(255,255,255,0.9)";
            } else {
              return "#333333";
            }
          },
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          count: 3,
          color: "#000000",
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
          callback: function (val, index) {
            if (index !== 0) {
              let x = ("" + val).length;
              let p = Math.pow;
              let d = p(10, 1);
              x -= x % 3;
              let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
              if (rt === "0K") {
                rt = "0";
              }
              return rt;
            }
          },
        },
      },
    },
  };

export const chartjs_options_for_Holdings = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 1,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      labels: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: function (handler) {
          externalTooltipHandlerForHoldings(handler);
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          color: "#000000",
          align: "center",
          minRotation: 0,
          maxRotation: 0,
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value === 0) {
              return "rgba(255,255,255,0.9)";
            } else {
              return "#333333";
            }
          },
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          count: 3,
          color: "#000000",
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
          callback: function (val, index) {
            if (index !== 0) {
              let x = ("" + val).length;
              let p = Math.pow;
              let d = p(10, 1);
              x -= x % 3;
              let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
              if (rt === "0K") {
                rt = "0";
              }
              return rt;
            }
          },
        },
      },
    },
  };



function getTooltipForStreaming() {
  let tooltip = document.querySelector(".collection-streaming-chart-tip");
  if (!tooltip) {
    tooltip = document.createElement("div");
    tooltip.className = "collection-streaming-chart-tip";

    const label = document.createElement("div");
    const line = document.createElement("span");
    line.className = "collection-streaming-chart-tip-line";
    tooltip.appendChild(label);
    tooltip.appendChild(line);
    document
      .querySelector(".collection-streaming-chart-box")
      .appendChild(tooltip);
  }
  return tooltip;
}

function externalTooltipHandlerForStreaming(context) {
  const chart = context.chart;
  const tooltip = context.tooltip;

  const el = getTooltipForStreaming();

  if (tooltip.opacity === 0) {
    el.style.opacity = 0;
    return;
  }

  const date = tooltip.title[0];
  const ary = date.split("/").map((val) => Number(val))

  const title = ary.join("<i>/</i>");
  const value = tooltip.body[0].lines[0];

  document.querySelector(".collection-streaming-chart-tip div").innerHTML =
    "<span>" + title + "</span><br><span>" + value + "</span>";

  const { offsetLeft: positionX} = chart.canvas;
  el.style.opacity = 1;
  el.style.left = positionX + tooltip.caretX + "px";
  document.querySelector(
    ".collection-streaming-chart-tip-line"
  ).style.height = tooltip.caretY + 20 + "px";
}

export const chartJs_options_for_Streaming = {
  responsive: true,
  maintainAspectRatio: false,
  devicePixelRatio: 1,
  layout: {
    padding: 0,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    labels: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: "nearest",
      external: function (handler) {
        externalTooltipHandlerForStreaming(handler);
      },
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
        color: "#ffffff",
      },
      ticks: {
        display: true,
        padding: 0,
        backdropPadding: 0,
        color: "#000000",
        align: "center",
        minRotation: 0,
        maxRotation: 0,
        font: {
          family: "Poppins",
          weight: "300",
          size: 11,
        },
      },
    },
    y: {
      border: {
        display: false,
      },
      beginAtZero: true,
      grid: {
        display: true,
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgba(255,255,255,0.9)";
          } else {
            return "#333333";
          }
        },
      },
      ticks: {
        display: true,
        padding: 0,
        backdropPadding: 0,
        count: 3,
        color: "#000000",
        font: {
          family: "Poppins",
          weight: "300",
          size: 11,
        },
        callback: function (val, index) {
          if (index !== 0) {
            let x = ("" + val).length;
            let p = Math.pow;
            let d = p(10, 1);
            x -= x % 3;
            let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
            if (rt === "0K") {
              rt = "0";
            }
            return rt;
          }
        },
      },
    },
  },
};