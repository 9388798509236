import { useQuery } from '@shopify/react-graphql';
import React, { useEffect, useState } from 'react'
import { GET_OWNER_LIMITED_CONTENT } from '../../query/collection';

const OWNER_LIMITED_CONTENT_SHOW_COUNT = 3;

export default function OwnerLimitedContent() {
  const [nextPageOwnerContent, setNextPageOwnerContent] = useState("")
  const [articlesOwnerContent, setArticlesOwnerContent] = useState([])
  const [loadingFetchMoreContent, setLoadingFetchMoreContent] = useState(false)
  const [
    ownerLimitedContentShowingCount,
    setOwnerLimitedContentShowingCount,
  ] = useState(OWNER_LIMITED_CONTENT_SHOW_COUNT);
   const {
    data: ownerLimitedContentData,
    fetchMore
  } = useQuery(GET_OWNER_LIMITED_CONTENT, {
    variables: { next_page: "" }
  });

  useEffect(()=>{
    if(ownerLimitedContentData) {
      setArticlesOwnerContent(ownerLimitedContentData?.oikosClubArticles?.articles)
      setNextPageOwnerContent(ownerLimitedContentData?.oikosClubArticles?.nextPageInfo)
    }
  }, [ownerLimitedContentData])

  function cvtArticlesOwnerContent(articles) {
    const lengthArticles = articles?.length
    const balance = lengthArticles % OWNER_LIMITED_CONTENT_SHOW_COUNT
    if(balance === 0 || !nextPageOwnerContent) return articles
    const splitArray = articles.slice(0, lengthArticles - balance)
    return [...splitArray]
  }

  const newArticlesOwnerContent = cvtArticlesOwnerContent(articlesOwnerContent)

  async function ShowMoreOwnerLimitedContent() {
    if(loadingFetchMoreContent) return
    const nextCount = ownerLimitedContentShowingCount + OWNER_LIMITED_CONTENT_SHOW_COUNT
    setOwnerLimitedContentShowingCount(nextCount)
    if(nextPageOwnerContent && newArticlesOwnerContent.length < nextCount) {
      setLoadingFetchMoreContent(true)
      await fetchMore(
      {     
        variables: { next_page: nextPageOwnerContent },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const result =  Object.assign({}, prev, {
            oikosClubArticles: {
              ...prev.oikosClubArticles,
              nextPageInfo: fetchMoreResult?.oikosClubArticles?.nextPageInfo,
              articles: [
                  ...prev?.oikosClubArticles?.articles,
                  ...fetchMoreResult?.oikosClubArticles?.articles
              ],
            }
          });
          return result
        }
      },)
      setLoadingFetchMoreContent(false)
    }    
  }

  const dateFormat = (value) => {
    const date = new Date(value)
    if(!value || !(date instanceof Date && !isNaN(date))) return ''
    const lang = 'ja';
    const year = date.toLocaleString(lang, {year:'numeric'});
    const month = date.toLocaleString(lang, {month:'numeric'});
    const day = date.toLocaleString(lang, {day:'numeric'});
    return `${year}${month}${day}`
  }

  return (
    <div className="owner-limited-content">
      <h2>オーナー限定コンテンツ</h2>
      <div className="inner">
        <div className="slide">
          {newArticlesOwnerContent.length > 0 &&
            newArticlesOwnerContent.map((log, i) => {
              return (
                <React.Fragment key={i}>
                  <div
                    className={
                      i + 1 > ownerLimitedContentShowingCount
                        ? "hide"
                        : "slide-item"
                    }
                  >
                    <a
                      href={
                        process.env.REACT_APP_OIKOS_URL +
                        "blogs/club_oikos/" +
                        log?.handle
                      }
                    >
                      <dl>
                        <dt>{dateFormat(log?.publishedAt)}</dt>
                        <dd>{log?.tags}</dd>
                      </dl>
                      <div className="title">{log?.title}</div>
                    </a>
                  </div>
                </React.Fragment>
              );
            })}
        </div>

        <nav>
          <span
            className={
              `button-big-outlined ${newArticlesOwnerContent.length > ownerLimitedContentShowingCount || nextPageOwnerContent
                ? "more"
                : "more disabled"}
              `
            }
            onClick={ShowMoreOwnerLimitedContent}
          >
            SHOW MORE
          </span>
        </nav>
      </div>
    </div>
  )
}
