import React from "react";
import { Line } from "react-chartjs-2";
import CountUp from "react-countup";
import { ShowUpDown, ConvertTerm } from "../CollectionDetails/utils/index";

const RoyaltyChart = ({
  data,
  onShowRoyalty,
  ShowSongRoyaltyDialog,
  width,
  height,
  selectedRoyaltyPeriod,
}) => {
  const chartjs_options_for_Royalty = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 1,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      labels: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: function (handler) {
          externalTooltipHandlerForRoyalty(handler);
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          color: "#000000",
          align: "center",
          minRotation: 0,
          maxRotation: 0,
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value == 0) {
              return "rgba(255,255,255,0.9)";
            } else {
              return "#333333";
            }
          },
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          count: 3,
          color: "#000000",
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
          callback: function (val, index) {
            if (index !== 0) {
              let x = ("" + val).length;
              let p = Math.pow;
              let d = p(10, 1);
              x -= x % 3;
              let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
              if (rt === "0K") {
                rt = "0";
              }
              return rt;
            }
          },
        },
      },
    },
  };
  function externalTooltipHandlerForRoyalty(context) {
    var chart = context.chart;
    var tooltip = context.tooltip;

    var el = getTooltipForRoyalty();

    if (tooltip.opacity === 0) {
      el.style.opacity = 0;
      return;
    }

    var date = tooltip.title[0];
    var array = [];
    date.split("/").map((val) => array.push(Number(val)));

    var title = array.join("<i>/</i>");
    var value = tooltip.body[0].lines[0];

    document.querySelector(".collection-royalty-chart-tip div").innerHTML =
      "<span>" + title + "</span><br><span>" + value + "</span>";

    var { offsetLeft: positionX } = chart.canvas;
    el.style.opacity = 1;
    el.style.left = positionX + tooltip.caretX + "px";
    document.querySelector(".collection-royalty-chart-tip-line").style.height =
      tooltip.caretY + 20 + "px";
  }
  function getTooltipForRoyalty() {
    var tooltip = document.querySelector(".collection-royalty-chart-tip");
    if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.className = "collection-royalty-chart-tip";

      var label = document.createElement("div");
      var line = document.createElement("span");
      line.className = "collection-royalty-chart-tip-line";
      tooltip.appendChild(label);
      tooltip.appendChild(line);
      document.querySelector(".detail-royalty-chart-box").appendChild(tooltip);
    }
    return tooltip;
  }
  function CreateRoyaltyData(data) {
    let labels = [];
    data.map((d) => {
      if (window.innerWidth >= 1024) {
        return labels.push(d.month.replaceAll("-", "/"));
      } else {
        let date = d.month.split("-");
        return labels.push(parseInt(date[1]) + "/" + parseInt(date[2]));
      }
    });
    let dt = [];
    data.map((d) => dt.push(d.amount));

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }

  function SumRoyaltyAmount(royalty) {
    return royalty.reduce((sum, element) => sum + element.amount, 0);
  }

  function ShowStrRoyaltyChangeRate() {
    if (data.songAnalysis.royalty.length > 0) {
      return (
        <>
          {ShowUpDown(CalcRoyaltyChangeRate(data))}
          過去{ConvertTerm(selectedRoyaltyPeriod)}で
          {CalcRoyaltyChangeRate(data)}
          %変化しました
        </>
      );
    } else {
      return <>※まだデータがありません</>;
    }
  }
  function CalcRoyaltyChangeRate(data) {
    if (data.songAnalysis.royalty.length > 0) {
      let chageRate =
        ((data.songAnalysis.royalty[data.songAnalysis.royalty.length - 1]
          .amount -
          data.songAnalysis.royalty[0].amount) /
          data.songAnalysis.royalty[0].amount) *
        100;
      return Math.ceil(chageRate);
    } else {
      return 0;
    }
  }

  return (
    <div className="detail-royalty-chart-group">
      <div className="chart">
        <h2>
          ロイヤリティー実績
          <span onClick={() => ShowSongRoyaltyDialog()} className="tips"></span>
        </h2>
        <dl>
          <dt>
            ¥
            <CountUp
              end={SumRoyaltyAmount(data.songAnalysis.royalty)}
              decimal="."
              decimals={
                SumRoyaltyAmount(data.songAnalysis.royalty) % 1 !== 0 ? 2 : 0
              }
            />
          </dt>
          <dd className="show">{ShowStrRoyaltyChangeRate()}</dd>
        </dl>
        <div className="detail-royalty-chart-box">
          <Line
            width={width}
            height={height}
            options={chartjs_options_for_Royalty}
            data={CreateRoyaltyData(data.songAnalysis.royalty)}
          />
        </div>
        <div className="chart-menu">
          <ul>
            {[90, 180, 365, 0].map((period) => (
              <li key={period}>
                <span
                  onClick={() => onShowRoyalty(period)}
                  className={selectedRoyaltyPeriod === period ? "active" : ""}
                >
                  {period === 0 ? "全期間" : `${period}日`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RoyaltyChart;
