// import { useContext } from 'react'
import { useQuery } from "@shopify/react-graphql";
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useRef } from "react";
import { CollectionList, OwnerLimitedContent } from "../components/collection";
import CustodyArea from "../components/collection/CustodyArea";
import OikosHoldingsArea from "../components/collection/OikosHoldingsArea";
import PlayCountArea from "../components/collection/PlayCountArea";
import RoyaltyAndHoldings from "../components/collection/RoyaltyAndHoldings";
import { GET_ITEMS } from "../query/collection";
// import { Context } from 'GlobalState';
// import Button from 'components/Button';

const Collection = () => {
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  const { error, data } = useQuery(GET_ITEMS);
  // const { state, dispatch } = useContext(Context);

  const collectionListRef = useRef(null);

  const scrollToCollectionList = () => {
    collectionListRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (error) {
    return (
      <main className="collection content">
        <div className="inner not-found">
          <p>コレクションページを開くにはログインが必要です。</p>
          <div className="function">
            <a href={process.env.REACT_APP_OIKOS_URL + "account/login"}>
              LOGINへ
            </a>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="collection content">
      <div className="inner">
        <h1 className="large">Collection</h1>
        <div className="content-label">
          <img src="img/typo_collection.svg" alt="" />
        </div>
        {/* <VariantProduct data={data}/> */}
        <OikosHoldingsArea onScrollToCollectionList={scrollToCollectionList} />
        <CustodyArea />
        {data?.ownedItems.length === 0 && (
          <div className="no-item-bought">
            <div className="inner not-found">
              <p>
                購入済みの商品はありません。
                <br />
                OIKOSを購入して、お気に入りのアーティストを応援しよう。
              </p>
              <div className="function">
                <a href={process.env.REACT_APP_OIKOS_URL + "collections/all"}>
                  商品を購入する
                </a>
              </div>
            </div>
          </div>
        )}
        <PlayCountArea />
        <RoyaltyAndHoldings />
        <OwnerLimitedContent />
        {/* { state.songDetailPage && (
          <Button 
            onClick={()=>{
              window.location.href = process.env.REACT_APP_OIKOS_URL+state.songDetailPage;
              dispatch({type: 'SET_SONG_DETAIL_PAGE', payload: ''})
            }}
            className="mt-20 mx-auto"
          >詳細ページへ</Button>)} */}
        <CollectionList data={data} ref={collectionListRef} />
      </div>
    </main>
  );
};
export default Collection;
