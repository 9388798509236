import React, { useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import { FormatPrice } from "../../utils";

const OWNED_ITEMS_SHOW_COUNT = 10;

const CollectionList = forwardRef(({ data }, ref) => {
  const [currentTab] = useState("owned");
  const [ownedItemsShowingCount, setOwnedItemsShowingCount] = useState(
    OWNED_ITEMS_SHOW_COUNT
  );

  function ShowMoreOwnedItems() {
    if (data?.ownedItems?.length > ownedItemsShowingCount) {
      setOwnedItemsShowingCount(
        ownedItemsShowingCount + OWNED_ITEMS_SHOW_COUNT
      );
    }
  }

  const isTotalPriceLesser = (trading_log) =>
    trading_log?.totalPriceInMigrate < trading_log?.totalPriceOld;

  const getPriceChange = (trading_log) => {
    const { totalPriceInMigrate, totalPriceOld } = trading_log || {};

    return isTotalPriceLesser(trading_log)
      ? totalPriceOld - totalPriceInMigrate
      : totalPriceInMigrate - totalPriceOld;
  };

  return (
    <div ref={ref} className="collection-list">
      <h2>保有OIKOS一覧</h2>
      <ul data-type="owned" className={currentTab === "owned" ? "active" : ""}>
        {data?.ownedItems?.length > 0 &&
          data?.ownedItems?.map((log, i) => {
            return (
              <li
                key={log.id}
                className={i + 1 > ownedItemsShowingCount ? "hide" : ""}
              >
                <Link to={"/" + log.id}>
                  <div className="jacket">
                    <img
                      src={log?.right?.thumbnail?.url}
                      alt={log?.right?.album?.title}
                      className="!h-[110px] object-cover"
                    />
                  </div>
                  <div className="info">
                    <div className="title">
                      {log?.right?.album?.title || log?.right?.song?.title}
                    </div>
                    <div className="artist">{log?.right?.artist?.name}</div>

                    <div className="data gap-4 !mt-0">
                      <dl>
                        <dd className="!text-xl">
                          ¥{FormatPrice(log?.totalPrice)}
                        </dd>
                      </dl>

                      <dl className="flex items-center mt-1">
                        <dd className="!mt-0">
                          {log.totalPriceOld !== 0 &&
                            log.totalPriceOld !== log.totalPriceInMigrate && (
                              <span
                                className={`text-white py-[4px] px-[7px] rounded ${
                                  isTotalPriceLesser(log)
                                    ? "bg-red-500"
                                    : "bg-green-500"
                                }`}
                              >
                                {isTotalPriceLesser(log) ? "-" : "+"}¥
                                {FormatPrice(getPriceChange(log))}
                              </span>
                            )}
                        </dd>
                      </dl>
                    </div>
                    <div className="data flex flex-col !mt-2">
                      <dl className="flex gap-1 !w-full align-middle">
                        <dt>保有総数 : </dt>
                        <dl className="flex gap-1">
                          <dd className="!mt-0 !text-[14px] !font-light">
                            {log?.right?.owned?.oikos}
                          </dd>
                          <dd className="!mt-0 !text-[14px] !font-light">
                            {log?.right?.productVariant?.shopifyVariantTitle.toUpperCase()}
                          </dd>
                        </dl>
                      </dl>
                      <dl className="flex gap-1 !w-full align-middle mt-[2px]">
                        <dt>収益分配率 : </dt>
                        <dd className="!mt-0 !text-[14px] !font-light">
                          {log?.right?.totalPercentage} %{" "}
                        </dd>
                      </dl>
                    </div>

                    {/* Comnet code - 2024/12/10 */}
                    {/* <ul>
                      <li className="group" onClick={(e)=>{e.preventDefault();e.stopPropagation()}}>
                        <span className="thumb">
                          <img
                            src="img/square_platinum.png"
                            alt="Platinum Icon"
                          />
                        </span>
                        <strong>{log?.right?.owned?.oikos || "0"}</strong>
                        {(log?.right?.ownedOld?.silver !== null || log?.right?.ownedOld?.gold !== null || log?.right?.ownedOld?.platinum !== null) && (
                        <div className=" cursor-pointer ml-1 relative h-full">
                          <Info className="w-3 h-3"/>
                          <div className="hidden group-hover:block absolute text-sm p-2 rounded border text-left min-w-[110px] bottom-[110%] left-[110%] bg-white z-[100]">
                            変換前：
                            <div className="flex flex-col justify-start ">
                              <div>・Silver {log?.right?.ownedOld?.silver ?? 0}</div>
                              <div>・Gold {log?.right?.ownedOld?.gold ?? 0}</div>
                              <div>・Platinum {log?.right?.ownedOld?.platinum ?? 0}</div>
                            </div>
                          </div>
                        </div>
                      )}
                      </li>
                    </ul> */}
                  </div>
                </Link>
              </li>
            );
          })}

        {data?.ownedItems?.length === 0 && (
          <div className="inner not-found">
            <p>
              購入済みの商品はありません。
              <br />
              OIKOSを購入して、お気に入りのアーティストを応援しよう。
            </p>
            <div className="function">
              <a href={process.env.REACT_APP_OIKOS_URL + "collections/all"}>
                商品を購入する
              </a>
            </div>
          </div>
        )}
      </ul>

      <nav>
        <span
          className={`button-big-outlined ${
            data?.ownedItems?.length > ownedItemsShowingCount
              ? "more"
              : "more disabled"
          }`}
          onClick={() => ShowMoreOwnedItems()}
        >
          SHOW MORE
        </span>
      </nav>
    </div>
  );
});

export default CollectionList;
