import { useOutletContext } from "react-router-dom";
import { GET_HOLDINGS_GRAPH } from "../../query/collection";
import { useQuery } from "@shopify/react-graphql";
import { FormatPrice } from "../../utils";

export default function OikosHoldingsArea({ onScrollToCollectionList }) {
  const context = useOutletContext();

  const { data: holdingsGraphData } = useQuery(GET_HOLDINGS_GRAPH, {
    variables: { period: 89 },
  });

  function ShowHoldingsTotalPrice(data) {
    if (data && data?.totalPricePerDay?.length > 0) {
      return data.totalPricePerDay[data?.totalPricePerDay?.length - 1].price;
    } else {
      return 0;
    }
  }

  return (
    <div className="custody-area">
      <div className="title">
        保有OIKOS総額
        <span onClick={() => context[1]?.()} className="tips"></span>
      </div>
      <div className="inner">
        <dl>
          <dt>
            ¥
            {FormatPrice(
              ShowHoldingsTotalPrice(holdingsGraphData) &&
                Math.floor(ShowHoldingsTotalPrice(holdingsGraphData) * 100) /
                  100
            )}
          </dt>
        </dl>
        <nav>
          <button
            className="button-big-container"
            onClick={() => onScrollToCollectionList()}
          >
            保有OIKOS一覧
          </button>
        </nav>
      </div>
    </div>
  );
}
