import { useNavigate, useOutletContext } from "react-router-dom";
import { GET_CUSTODY } from "../../query/collection";
import { useQuery } from "@shopify/react-graphql";
import { FormatPrice } from "../../utils";

export default function CustodyArea() {
  const [ShowCustodyDialog] = useOutletContext();
  const navigate = useNavigate();

  const { data: custodyData } = useQuery(GET_CUSTODY);

  function GoToPayout() {
    navigate("/payout");
  }

  return (
    <div className="custody-area !my-10">
      <div className="title">
        預かり金総額
        <span onClick={() => ShowCustodyDialog()} className="tips"></span>
      </div>
      <div className="inner">
        <dl>
          <dt>
            ¥
            {FormatPrice(
              custodyData?.totalAndPayableAmount?.totalAmount &&
                Math.floor(
                  custodyData?.totalAndPayableAmount?.totalAmount * 100
                ) / 100
            )}
          </dt>
          <dd>
            (払出可能金額 : ¥
            {FormatPrice(custodyData?.totalAndPayableAmount?.payableAmount)})
          </dd>
        </dl>
        <nav>
          <button className="button-big-container" onClick={() => GoToPayout()}>
            内訳・払出
          </button>
        </nav>
      </div>
    </div>
  );
}
