import React from "react"

const Button = ({
  className = "",
  variant = "contained",
  disabled = false,
  children,
  ...props
}) => {
  const variantClass = {
    contained: "bg-blur-contained text-right text-white",
    outlined: "border-2 border-solid border-black text-black",
  }
  const disabledClass = disabled && "opacity-20 pointer-events-none"
  const baseClass = `flex justify-center h-34px lg:h-40px min-w-250px lg:min-w-310px w-auto px-4 text-sm lg:text-15px rounded-30px transition-all duration-300 ease-out items-center ${variantClass[variant]} ${disabledClass} ${className}`

  return (
    <button className={baseClass} {...props}>
      {children}
    </button>
  )
}

export default Button
