import { FormatPrice } from "../../utils";

const OwnedItems = ({ ownedItems, tradingLogOlds }) => {
  const calculatePriceByBoughtAt = (shopifyOrderId) =>
    tradingLogOlds
      .filter((item) => item?.shopifyOrderId === shopifyOrderId)
      .reduce((total, item) => total + item.price, 0);

  const calculateDiscountOrderOld = (shopifyOrderId) => {
    const processedVariants = new Set();
    let totalDiscountCount = 0;
    let allDiscountAdded = false;

    tradingLogOlds
      .filter((item) => item.shopifyOrderId === shopifyOrderId)
      .forEach((item) => {
        if (item.targetSelection === "all" && !allDiscountAdded) {
          totalDiscountCount += item.discountCount;
          allDiscountAdded = true;
          processedVariants.add(item.productVariantId);
        } else if (!processedVariants.has(item.productVariantId)) {
          processedVariants.add(item.productVariantId);
          totalDiscountCount += item.discountCount;
        }
      });

    return totalDiscountCount;
  };

  const totalPrice = (right) => {
    const oldPrice = calculatePriceByBoughtAt(right.shopifyOrderId);
    const oldDiscount = calculateDiscountOrderOld(right.shopifyOrderId);

    if (
      tradingLogOlds.filter(
        (item) => item?.shopifyOrderId === right.shopifyOrderId
      ).length > 0
    ) {
      return oldDiscount ? oldPrice - oldDiscount : oldPrice;
    }

    return right?.discountCount
      ? right.sumPriceOrder - right?.discountCount
      : right.sumPriceOrder;
  };

  const formattedDate = (date) => {
    const newDate = new Date(date);
    const formattedDate = new Intl.DateTimeFormat("ja-JP", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(newDate);

    return formattedDate.replace(/\//g, "-").replace(" ", ", ");
  };

  return (
    <div className="oikos-compact">
      <h2>取引履歴</h2>
      <table className="table table-auto table-owned w-full">
        <thead className="bg-gray-200">
          <tr>
            <th className="border-b font-medium text-black-200 text-left">
              注文日
            </th>
            <th className="border-b font-medium text-black-200">個数</th>
            <th className="border-b font-medium text-black-200">価格</th>
          </tr>
        </thead>
        <tbody>
          {ownedItems.map((right, index) => (
            <tr>
              <td className="border-b text-black-500">
                {formattedDate(right.newBoughtAt)}
              </td>
              <td className="border-b text-black-500 text-right">
                {right.quantity}
              </td>
              <td className="border-b text-black-500 text-right">
                ¥{FormatPrice(totalPrice(right))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Comment code - OIKOS-149 - 2024/12/11 */}
      {/* <ul>
        {ownedItems.map((right, index) => (
          <li
            key={index}
            className={index + 1 > ownedItemsShowingCount ? "hide" : ""}
          >
            <Link onClick={() => showModal(index)}>
              <div className="thumb">
                <img src={"img/bar_platinum2.png"} alt="" />
              </div>
              <div className="info">
                <div className="title">
                  OIKOS
                  {" " +
                    right.productVariant.shopifyVariantTitle
                      .charAt(0)
                      .toUpperCase() +
                    right.productVariant.shopifyVariantTitle.slice(1)}
                </div>
                <div className="data">
                  <dl>
                    <dt>収益分配率</dt>
                    <dd>{right.sumPercentageOrder}%</dd>
                  </dl>
                  <dl>
                    <dt>保有額</dt>
                    <dl className="flex gap-1">
                      <dd>
                        ¥
                        {right.sumPriceOrder.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </dd>
                      <dd>
                        {" "}
                        {right.sumPriceOrder ===
                        calculatePriceByBoughtAt(right.newBoughtAt)
                          ? "± 0"
                          : ""}{" "}
                      </dd>
                    </dl>
                  </dl>
                  <dl>
                    <dt>購入日</dt>
                    <dd>
                      {right.newBoughtAt &&
                        new Date(right.newBoughtAt)
                          .toLocaleString("ja-JP", { dateStyle: "short" })
                          .replace(/\//g, "-")}
                    </dd>
                  </dl>
                  <dl>
                    <dt>数量</dt>
                    <dd>{right.quantity}</dd>
                  </dl>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <nav>
        <span
          onClick={() => onShowMoreOwnedItems()}
          className={
            ownedItems.length > ownedItemsShowingCount
              ? "more"
              : "more disabled"
          }
        >
          SHOW MORE
        </span>
      </nav> */}
    </div>
  );
};

export default OwnedItems;
